<template>
  <vx-card class="insert-company-page main-box top-zero-radius md:w-1/2" no-shadow>
    <vs-row
      vs-type="flex"
      vs-justify="space-around">

      <!-- Img Row -->
      <div class="flex flex-wrap items-center mb-base">
        <vs-row
          vs-type="flex"
          vs-justify="space-around">
          <vs-avatar
            :src="clubInfo.logo ?`https://sport.useral.com/api/${clubInfo.logo}` : 'https://s16.picofile.com/file/8421955526/USERAL_logo.png'"
            size="150px"
            class="mr-4 mb-4"
            @click="activeLogoPrompt = true"/>

          <!-- avatar selector -->
          <vs-prompt
            color="danger"
            :buttons-hidden="true"
            :title="$t('products.insertProduct.accordionList.category.title')"
            :active.sync="activeLogoPrompt">

            <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
              <vs-row>
                <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">

                </vs-col>

                <vs-spacer/>

                <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                  آپلود لوگو
                </vs-col>

                <vs-spacer/>

                <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                  <div @click="activeLogoPrompt = false">
                    انصراف
                  </div>
                </vs-col>
              </vs-row>
            </div>

            <div class="prompt-content py-2 px-3">
              <transition name="fade">
                <vs-row class="my-3" vs-type="flex" vs-justify="between" v-if="clubInfo.logo">
                  <vs-col>
                    پیشنمایش:
                    <img class="rounded mt-2"
                         width="100%"
                         height="150px"
                         style="object-fit: contain"
                         :src="clubInfo.logo ? `https://sport.useral.com/api/${clubInfo.logo}` : ''">
                  </vs-col>
                </vs-row>
              </transition>
              <vs-row class="my-3" vs-type="flex" vs-justify="center">
                <vs-col class="w-5/12 mr-2">
                  <input type="file" id="browseLogo" @change="uploadAvatar" hidden>
                  <vs-button class="w-full"
                             icon="icon-upload"
                             icon-pack="feather"
                             @click="openChooseFileDialog">
                    {{ $t('setting.buttons.uploadLogo') }}
                  </vs-button>
                </vs-col>
                <vs-col class="w-5/12">
                  <vs-button class="w-full"
                             type="border"
                             color="danger"
                             icon="icon-trash"
                             :disabled="clubInfo.logo === ''"
                             icon-pack="feather" @click="removeAvatar">
                    {{ $t('setting.buttons.removeLogo') }}
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </vs-prompt>
          <!-- /avatar selector -->
        </vs-row>

        <vs-row
          vs-type="flex"
          vs-justify="center">
          <p class="w-4/5 text-xs text-center mt-2">{{ $t('setting.General.logo.rule') }}</p>
        </vs-row>
      </div>
      <vs-divider class="my-5"></vs-divider>
    </vs-row>

    <vs-row>

      <vs-col class="w-full md:w-1/3 d-inline-flex">
        <p class="h5 text-primary">اطلاعات عمومی شرکت</p>
        <span class="text-justify text-xs">در این باکس اطلاعات عمومی شرکت نمایش داده می شود.</span>
      </vs-col>

      <vs-col class="w-full md:w-2/3 d-inline-flex">

        <!-- club code input -->
        <vs-input class="w-full mb-base"
                  :label-placeholder="$t('setting.General.club.code')"
                  v-model="clubInfo.code"/>

        <!-- club name input -->
        <vs-input class="w-full mb-base"
                  :label-placeholder="$t('setting.General.club.name')"
                  v-model="clubInfo.name"/>

        <!-- club manager name input -->
        <vs-input class="w-full mb-base"
                  :label-placeholder="$t('setting.General.club.managerName')"
                  v-model="clubInfo.managerName"/>
      </vs-col>

      <vs-divider class="my-5"></vs-divider>
    </vs-row>

    <vs-row>

      <vs-col class="w-full md:w-1/3 d-inline-flex">
        <p class="h5 text-primary">اطلاعات تماس شرکت</p>
        <span class="text-justify text-xs">در این باکس اطلاعات تماس شرکت نمایش داده می شود.</span>
      </vs-col>

      <vs-col class="w-full md:w-2/3 d-inline-flex">

        <!-- club address input -->
        <vs-input class="w-full mb-base"
                  :label-placeholder="$t('setting.General.club.address')"
                  v-model="clubInfo.address"/>

        <!-- club phone number input -->
        <vs-input class="w-full mb-base"
                  :label-placeholder="$t('setting.General.club.phoneNumber')"
                  v-model="clubInfo.phoneNumber"/>

        <!-- club manger phone number input -->
        <vs-input class="w-full"
                  :label-placeholder="$t('setting.General.club.managerPhoneNumber')"
                  v-model="clubInfo.managerPhoneNumber"/>
      </vs-col>

      <vs-divider class="my-5"></vs-divider>
    </vs-row>

    <vs-row>

      <vs-col class="w-full md:w-1/3 d-inline-flex">
        <p class="h5 text-primary">اطلاعات مالی</p>
        <span class="text-justify text-xs">در این باکس اطلاعات مالی شرکت نمایش داده می شود.</span>
      </vs-col>

      <vs-col class="w-full md:w-2/3 d-inline-flex">

        <!-- club begin financial year -->
        <vs-input class="w-full mb-base"
                  id="selectBeginDate"
                  label-placeholder="شروع سال مالی"
                  v-model="clubInfo.financial.from"/>

        <date-picker element="selectBeginDate"
                     v-model="clubInfo.financial.from"
                     color="#131314"
                     style="color: #3c3c3c !important;"/>

        <!-- club end financial year -->
        <vs-input class="w-full"
                  id="selectEndDate"
                  label-placeholder="انتهای سال مالی"
                  v-model="clubInfo.financial.to"/>

        <date-picker element="selectEndDate"
                     v-model="clubInfo.financial.to"
                     :min="clubInfo.financial.from"
                     color="#131314"
                     style="color: #3c3c3c !important;"/>
      </vs-col>

    </vs-row>

    <vs-row>

      <vs-col class="w-full">

        <!-- Save & Reset Button -->
        <div class="flex flex-wrap items-center justify-end">
          <vs-button id="saveBTN" @click="sendForm" class="useral-action-button ml-auto mt-2" color="success">
            {{ $t('setting.buttons.save') }}
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import {mapGetters} from 'vuex'
import {editSetting, uploadAvatar} from '@/http/requests/setting'
import datePicker from 'vue-persian-datetime-picker'

export default {
  name: 'insertCompany',
  components: {
    datePicker
  },
  metaInfo: {
    title: 'شرکت'
  },
  data () {
    return {
      firstVisit: true,
      firstVisitTimer: 0,
      clubInfo: {
        code: '',
        name: '',
        managerName: '',
        logo: 'storage/images/logo/white-logo-PNG.png',
        address: '',
        managerPhoneNumber: '',
        phoneNumber: '',
        financial: {
          from: '',
          to: ''
        }
      },
      actions: {
        toolbar: [
          {
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success',
            type: 'link',
            id: {name: 'companies'}
          }
        ],
        leftToolbar: [
          {
            id: {name: 'companies'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      }
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto md:w-1/2')
    }, 50)
  },
  methods: {
    openChooseFileDialog () {
      document.getElementById('browseLogo').click()
    },
    avatarValidation (avatar) {
      if (avatar.size > (800 * 1026)) {
        this.$vs.notify({
          time: 4000,
          title: 'خطا',
          text: 'حجم فایل انتخاب شده بیش از حد مجاز است',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      let logoFormat = avatar.name.split('.')
      logoFormat = logoFormat[logoFormat.length - 1].toLowerCase()
      if (logoFormat !== 'png' && logoFormat !== 'jpg' && logoFormat !== 'jpeg') {
        this.$vs.notify({
          time: 4000,
          title: 'خطا',
          text: 'فرمت فایل انتخاب شده مجاز نیست',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      return true
    },
    uploadAvatar (event) {
      if (event.target.files && this.avatarValidation(event.target.files[0])) uploadAvatar(event.target.files[0]).then(response => {
        this.clubInfo.logo = response.data.path
        this.$vs.notify({
          time: 2400,
          title: 'پیغام',
          text: 'لوگو با موفقیت آپلود شد',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
      }).catch(error => {
        this.$vs.notify({
          time: 2400,
          title: 'خطا',
          text: error.response.data.error || error.response.data.avatar,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    removeAvatar () {
      this.clubInfo.logo = ''
      this.$vs.notify({
        time: 2400,
        title: 'پیغام',
        text: 'لوگو با موفقیت حذف شد',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'success'
      })
    },
    sendForm () {
      editSetting({
        club_code: this.clubInfo.code,
        club_name: this.clubInfo.name,
        club_logo: this.clubInfo.logo
      }).then(response => {
        this.$vs.notify({
          time: 2400,
          title: 'پیغام',
          text: 'اطلاعات فروشگاه با موفقیت بروز شد',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })

        this.$store.dispatch('removePageChanges')
      }).catch(error => {
        this.$vs.notify({
          time: 2400,
          title: 'خطا',
          text: error.response.data.message || error.response.data.error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.$store.dispatch('setting/setClubInfo')
    }
  },
  watch: {
    clubInfo: {
      handler (val, oldVal) {
        clearTimeout(this.firstVisitTimer)
        this.firstVisitTimer = setTimeout(() => {
          this.firstVisit = false
        }, 500)

        if (!this.firstVisit) {
          this.$store.dispatch('setPageChanges')
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
